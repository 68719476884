import { SharedCookieNames } from '@maersk-global/apmt-dpos-common';
import { useTranslation } from 'react-i18next';
import { useCookie } from 'react-use';
import { useShallow } from 'zustand/shallow';

import { triggerReset } from '@/api';
import { Block } from '@/components/atoms/Block/Block';
import { ErrorComponent } from '@/components/atoms/Error/ErrorComponent';
import { Notification } from '@/components/atoms/Notification/Notification';
import { Title } from '@/components/atoms/Title/Title';
import { QuayCranePushWarning } from '@/components/molecules/QuayCranePushWarning/QuayCranePushWarning';
import { QuayCraneInstructionsButtons } from '@/components/organisms/QuayCraneInstructions/QuayCraneInstructionsButtons';
import { useTerminalStore } from '@/store/terminalStore';
import { YardWork } from '@/store/yardStore';
import { WorkQueues, YardCheType } from '@/types';
import { toQuayCraneTableData } from '@/utils/quayCraneTableData';

import { QuayCraneInstructionsTable } from '../QuayCraneInstructionsTable';

export const QuayCraneInstructions = ({
    workQueues,
    showButtonLabels = true,
    quayCraneName,
    yardWork,
    vesselVisitId,
    consideredCompletedTruckNames,
    delayCodeActive,
    yardCheTypes,
    isAdheringInstructions,
    isSendingInstructions,
}: {
    workQueues: WorkQueues[];
    vesselVisitId: string;
    yardWork: YardWork;
    showButtonLabels?: boolean;
    quayCraneName: string;
    consideredCompletedTruckNames: string[];
    delayCodeActive: boolean;
    yardCheTypes: { [cheId: string]: YardCheType };
    isAdheringInstructions: boolean;
    isSendingInstructions: boolean;
}) => {
    const { t } = useTranslation();
    const terminalId = useTerminalStore(useShallow(state => state.terminalId));

    const [yardEnabled] = useCookie(SharedCookieNames.YardPage);
    if (workQueues.length === 0) {
        return (
            <ErrorComponent>
                {t('pages.flows.workQueuesError', { name: quayCraneName })}
            </ErrorComponent>
        );
    }

    const handleResetClicked = async (truckName: string) => {
        await triggerReset(terminalId, quayCraneName, vesselVisitId, [truckName]);
    };
    const instructionsPerWorkQueue = toQuayCraneTableData(
        workQueues,
        consideredCompletedTruckNames,
        quayCraneName,
        !!yardEnabled,
        yardWork,
        yardCheTypes,
        t,
    );

    return (
        <div className="flex flex-col gap-3" data-testid="quay_crane_instructions_panel">
            <Block className="items-center justify-between">
                <Title className="mds-font--display-1 flex items-center gap-1">
                    <span className="mds-font--default--bold">{t('labels.instructions')}</span>
                </Title>
                <QuayCraneInstructionsButtons
                    quayCraneName={quayCraneName}
                    terminalId={terminalId}
                    vesselVisitId={vesselVisitId || ''}
                    showButtonLabels={showButtonLabels}
                />
            </Block>
            <Block className="items-center">
                <Warnings
                    showVmtWarning={!isSendingInstructions}
                    isAdheringInstructions={isAdheringInstructions}
                />
            </Block>
            <QuayCraneInstructionsTable
                instructionsPerWorkQueue={instructionsPerWorkQueue}
                delayCodeActive={delayCodeActive}
                handleResetClicked={handleResetClicked}
            />
        </div>
    );
};

const Warnings = ({
    showVmtWarning,
    isAdheringInstructions,
}: {
    showVmtWarning: boolean;
    isAdheringInstructions: boolean;
}) => {
    if (showVmtWarning) {
        return (
            <Notification
                fit="medium"
                className="mb-2 py-2"
                appearance="cancel"
                body={
                    <div className="flex flex-row items-center ">
                        <span>
                            DPOS is <span className="underline">not</span> sending instructions to
                            the VMT
                        </span>
                    </div>
                }
            />
        );
    } else if (!isAdheringInstructions) {
        return <QuayCranePushWarning />;
    }
};
