import {
    array,
    date,
    Codec,
    exactly,
    GetType,
    string,
    enumeration,
    number,
    oneOf,
} from 'purify-ts';

import { TerminalArea } from '@/constants/enums';

import { optionalNullToUndefined } from './util/optionalNullToUndefined';

export enum AdherenceStatus {
    ADHERING = 'ADHERING',
    NOT_ADHERING = 'NOT_ADHERING',
}

export const tooManyTrucksInFlowPositionDecoder = Codec.interface({
    type: exactly('TooManyTrucksInFlowPosition'),
    flowPosition: enumeration(TerminalArea),
    truckCount: number,
});

export const tooManyTrucksConsideredCompleteDecoder = Codec.interface({
    type: exactly('TooManyTrucksConsideredComplete'),
    consideredCompleteCount: number,
});

export const instructionAdherenceDecoder = Codec.interface({
    quayCraneName: string,
    status: enumeration(AdherenceStatus),
    decidedAt: date,
    reasons: optionalNullToUndefined(
        array(oneOf([tooManyTrucksInFlowPositionDecoder, tooManyTrucksConsideredCompleteDecoder])),
    ),
});

export type InstructionAdherenceUpdatedEventPayload = GetType<typeof instructionAdherenceDecoder>;

export const instructionAdherenceUpdatedEventDecoder = Codec.interface({
    event: exactly('INSTRUCTION_ADHERENCE_UPDATED'),
    data: instructionAdherenceDecoder,
});

export type InstructionAdherenceUpdatedEvent = GetType<
    typeof instructionAdherenceUpdatedEventDecoder
>;
