import { SvgQuayCrane } from '@maersk-global/apmt-react-icons';
import { useTranslation } from 'react-i18next';

import { cn } from '@/utils/cn';

export type QuayCraneVMTWarningProps = {
    shortText?: boolean;
    className?: string;
};

export const QuayCraneVMTWarning = ({ className }: QuayCraneVMTWarningProps) => {
    const { t } = useTranslation();

    return (
        <div
            className={cn(
                'flex items-center gap-2 px-2 py-[6px] rounded bg-neutral-600 text-white',
                className,
            )}
        >
            <SvgQuayCrane />
            <span className="mds-font--default">{t('labels.vmtWarning.short')}</span>
        </div>
    );
};
