import { SharedCookieNames } from '@maersk-global/apmt-dpos-common';
import { Role, useAuth } from '@maersk-global/apmt-flow-keycloak';
import { FeatureFlagV1 } from '@maersk-global/digital-pull-operating-system-spec';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { GridItem } from '@/components/atoms/GridItem/GridItem';
import { GridOfCards } from '@/components/atoms/GridOfCards/GridOfCards';
import { FeatureFlagCard } from '@/components/organisms/Cards/FeatureFlagCard/FeatureFlagCard';
import { SettingsCard } from '@/components/organisms/Cards/SettingsCard/SettingsCard';
import { CookieNames } from '@/constants/cookies';
import { useTerminalStore } from '@/store/terminalStore';

const backendFeatureFlagAccess: Record<FeatureFlagV1, Role[]> = {
    VMT_INSTRUCTIONS: [Role.Normal, Role.Admin],
    NO_AD_TRIGGERS: [], // we dont show this setting here because it is a crane flag
    OPPOSITE_DRIVING_DIRECTIONS: [], // we dont show this setting here because it is a crane flag
};

export const SettingsPage = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const setPageTitle = useTerminalStore(state => state.setPageTitle);
    const frontendFeatureFlags = Object.values(SharedCookieNames);

    const backendFeatureFlags = Object.values(FeatureFlagV1).filter(
        flag =>
            backendFeatureFlagAccess[flag]?.some(roleToAccess =>
                user.roles.includes(roleToAccess),
            ) || false,
    );

    useEffect(() => {
        setPageTitle(t('pages.settings.pageTitle'));
    }, [setPageTitle]);

    return (
        <div className="mx-auto size-full p-6">
            <GridOfCards>
                {frontendFeatureFlags.map(flag => {
                    return (
                        <GridItem key={flag}>
                            <SettingsCard
                                title={t(`pages.settings.featureFlags.${flag}.title`)}
                                description={t(`pages.settings.featureFlags.${flag}.description`)}
                                cookieName={flag}
                            />
                        </GridItem>
                    );
                })}
                {backendFeatureFlags &&
                    backendFeatureFlags.map(flag => {
                        return (
                            <GridItem key={flag}>
                                <FeatureFlagCard />
                            </GridItem>
                        );
                    })}
                {user.isAdmin && (
                    <>
                        <GridItem>
                            <SettingsCard
                                title={t(`pages.settings.debugMode.title`)}
                                description={t(`pages.settings.debugMode.description`)}
                                cookieName={CookieNames.DebugMode}
                            />
                        </GridItem>
                        <GridItem>
                            <SettingsCard
                                title={'SSE logs'}
                                description={
                                    'Show logs from SSE connections in the browser console.'
                                }
                                cookieName={CookieNames.SseLogs}
                            />
                        </GridItem>
                        <GridItem>
                            <SettingsCard
                                title={'TOS only crane triggers'}
                                description={'Show toggle to enable TOS only crane triggers'}
                                cookieName={CookieNames.TosOnlyCraneTriggers}
                            />
                        </GridItem>
                    </>
                )}
            </GridOfCards>
        </div>
    );
};
