import { SvgTruckSide } from '@maersk-global/apmt-react-icons';
import { useTranslation } from 'react-i18next';

import { cn } from '@/utils/cn';

export type QuayCranePushWarningProps = {
    shortText?: boolean;
    className?: string;
};

export const QuayCranePushWarning = ({
    shortText = false,
    className,
}: QuayCranePushWarningProps) => {
    const { t } = useTranslation();

    return (
        <div
            className={cn(
                'flex items-center gap-2 px-2 py-[6px] rounded bg-feedback-warning-weak',
                className,
            )}
        >
            <SvgTruckSide className="text-xl" />
            <span className="mds-font--default">
                {shortText
                    ? t('labels.instructionAdherence.notFollowingInstructions.short')
                    : t('labels.instructionAdherence.notFollowingInstructions.long')}
            </span>
        </div>
    );
};
