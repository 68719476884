import { SvgCheck } from '@maersk-global/apmt-react-icons';

import { TagContainerSmall } from '@/components/atoms/TagContainerSmall/TagContainerSmall';
import { FlowVisualisationVariant, VisualAppearance } from '@/constants/enums';
import ApmPictoShipPortCraneIcon from '@/icons/components/QuayCraneIcon';
import { FlowStatus } from '@/types';
import { cn } from '@/utils/cn';

import { TruckWithAppeareance } from '../FlowPosition/FlowPosition';

type ComponentProps = {
    name: string;
    andon?: boolean;
    trucks: TruckWithAppeareance[];
    variant?: FlowVisualisationVariant;
    flowStatus?: FlowStatus;
};

export const QuayCranePosition = ({
    andon,
    trucks,
    flowStatus,
    variant = FlowVisualisationVariant.LARGE,
}: ComponentProps) => {
    const isLargeVariant = variant === FlowVisualisationVariant.LARGE;
    const isSmallVariant = variant === FlowVisualisationVariant.SMALL;

    return (
        <div
            className={cn('flex h-full flex-col items-center', {
                'relative -top-4': isSmallVariant,
                'relative -top-9': isLargeVariant,
            })}
        >
            <div className="">
                <ApmPictoShipPortCraneIcon
                    className={cn({
                        '-mx-2': isLargeVariant,
                        'text-gray-600': !andon,
                        'text-feedback-danger': andon,
                    })}
                    size={isLargeVariant ? 102 : 76}
                />
            </div>

            {isSmallVariant ? (
                <div className="mt-[-16px]">
                    <TagContainerSmall flowStatus={flowStatus}>
                        {trucks.map(item => {
                            const { truckId } = item;
                            return (
                                <div
                                    key={truckId}
                                    className="mds-font--x-small flex flex-row items-center leading-3"
                                >
                                    {item.appearance === VisualAppearance.DONE && (
                                        <span className="flex h-3 w-5 items-center ">
                                            <SvgCheck className="text-gray-900" />
                                        </span>
                                    )}
                                    {item.appearance === VisualAppearance.IN_PROGRESS && (
                                        <span className="flex items-center">
                                            <span className="relative inline-flex h-3 w-5 items-center">
                                                <span className="absolute left-px size-1 animate-dot-1 rounded bg-gray-900"></span>
                                                <span className="absolute left-[7px] size-1 animate-dot-2 rounded bg-gray-900"></span>
                                                <span className="absolute left-[13px] size-1 animate-dot-3 rounded bg-gray-900"></span>
                                            </span>
                                        </span>
                                    )}
                                    {truckId}
                                </div>
                            );
                        })}
                    </TagContainerSmall>
                </div>
            ) : null}

            {isLargeVariant ? (
                <div className="mt-[-24px]">
                    <TagContainerSmall flowStatus={flowStatus} variant="large">
                        {trucks.map(item => {
                            const { truckId } = item;
                            return (
                                <div
                                    key={truckId}
                                    className="mds-font--default flex flex-row px-1.5 leading-5"
                                >
                                    {item.appearance === VisualAppearance.DONE && (
                                        <span className="flex size-5 items-center ">
                                            <SvgCheck className="text-gray-900" />
                                        </span>
                                    )}
                                    {item.appearance === VisualAppearance.IN_PROGRESS && (
                                        <span className="flex items-center">
                                            <span className="relative inline-flex size-5 items-center">
                                                <span className="absolute left-px size-1 animate-dot-1 rounded bg-gray-900"></span>
                                                <span className="absolute left-[7px] size-1 animate-dot-2 rounded bg-gray-900"></span>
                                                <span className="absolute left-[13px] size-1 animate-dot-3 rounded bg-gray-900"></span>
                                            </span>
                                        </span>
                                    )}

                                    {truckId}
                                </div>
                            );
                        })}
                    </TagContainerSmall>
                </div>
            ) : null}
        </div>
    );
};
