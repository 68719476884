import { Button, Table, TooltipProvider } from '@maersk-global/apmt-react-components';
import { SvgBucketMop, SvgCheck, SvgEye, SvgLock, SvgPause } from '@maersk-global/apmt-react-icons';
import { FlowPositionEnum } from '@maersk-global/digital-pull-operating-system-spec';
import { createColumnHelper } from '@tanstack/react-table';
import { Maybe } from 'purify-ts';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FlowPositionName, WorkQueueType } from '@/constants/enums';
import { AssignedPosition } from '@/types';
import { cn } from '@/utils/cn';
import { InstructionsPerWorkQueue } from '@/utils/quayCraneTableData';

import { FlowDetailDrawerButtonTooltip } from './QuayCraneInstructions/QuayCraneInstructionsButtons';
import { WorkQueueTitle } from './QuayCraneInstructions/WorkQueuWrapper';
import { NoTruckInstructions } from '../atoms/NoTruckInstructions/NoTruckInstructions';

export type QuayCraneWorkQueueTableData = {
    instruction: string;
    hasReachedFlowPosition: Maybe<boolean>;
    isLocked: Maybe<boolean>;
    truckName: string;
    originalInstruction: FlowPositionName | AssignedPosition;
    handledCraneTriggers: number | undefined;
    expectedCraneTriggers: number | undefined;
    to: string;
    consideredComplete: boolean;
    kind: 'QC' | 'RTG';
    rowClassName?: string;
};
export const QuayCraneInstructionsTable = ({
    instructionsPerWorkQueue,
    delayCodeActive,
    handleResetClicked,
    toggleRtgCard,
}: {
    instructionsPerWorkQueue: InstructionsPerWorkQueue;
    delayCodeActive: boolean;
    handleResetClicked: (truckName: string) => void;
    toggleRtgCard?: (cheId: string) => void;
}) => {
    const columnHelper = createColumnHelper<QuayCraneWorkQueueTableData>();
    const { t } = useTranslation();
    const defaultColumns = useMemo(
        () => [
            columnHelper.display({
                id: 'truckName',
                header: () => <span>Truck</span>,
                cell: data => {
                    return (
                        <span
                            className={`flex flex-row items-center justify-start font-sansNumeric ${
                                data.row.original.consideredComplete ? 'opacity-30' : ''
                            }`}
                        >
                            {delayCodeActive &&
                            data.row.original.kind === 'QC' &&
                            data.row.original.originalInstruction ===
                                FlowPositionEnum.UNDER_CRANE ? (
                                <SvgPause width={24} height={24} />
                            ) : (
                                data.row.original.hasReachedFlowPosition
                                    .map(hasReached => {
                                        if (hasReached) {
                                            return (
                                                <span className="flex size-5 items-center ">
                                                    <SvgCheck className="text-green-500" />
                                                </span>
                                            );
                                        } else {
                                            return (
                                                <span className="flex items-center">
                                                    <span className="relative inline-flex size-5 items-center">
                                                        <span className="absolute left-px size-1 animate-dot-1 rounded bg-blue-600"></span>
                                                        <span className="absolute left-[7px] size-1 animate-dot-2 rounded bg-blue-600"></span>
                                                        <span className="absolute left-[13px] size-1 animate-dot-3 rounded bg-blue-600"></span>
                                                    </span>
                                                </span>
                                            );
                                        }
                                    })
                                    .caseOf({
                                        Just: e => e,
                                        Nothing: () => <div className="size-5"></div>,
                                    })
                            )}
                            {data.row.original.truckName}
                        </span>
                    );
                },
                size: 72,
            }),
            columnHelper.display({
                id: 'to',
                header: () => <span>To</span>,
                cell: data => {
                    return (
                        <div className="flex items-center">
                            <span
                                className={`font-sansNumeric ${
                                    data.row.original.consideredComplete ? 'opacity-30' : ''
                                } `}
                            >
                                {data.row.original.to}
                            </span>
                            {toggleRtgCard !== undefined && data.row.original.kind === 'RTG' && (
                                <Button
                                    variant="plain"
                                    fit="small"
                                    onClick={() => {
                                        return toggleRtgCard(data.row.original.to);
                                    }}
                                    data-testid="open-rtg-card"
                                    className="ml-1 h-4 cursor-pointer p-0 text-blue-200 hover:text-blue-400"
                                >
                                    <SvgEye className={cn('shrink-0 text-xl ', {})} />
                                </Button>
                            )}
                        </div>
                    );
                },
                size: 64,
            }),
            columnHelper.display({
                id: 'instruction',
                header: () => <span>Instruction</span>,
                cell: data => {
                    return (
                        <span
                            className={`flex flex-row ${
                                data.row.original.consideredComplete ? 'opacity-30' : ''
                            }`}
                        >
                            {data.row.original.instruction}
                            {data.row.original.expectedCraneTriggers !== undefined ? (
                                <span className="ml-1 opacity-30 ">{`(${data.row.original.handledCraneTriggers}/${data.row.original.expectedCraneTriggers})`}</span>
                            ) : null}
                            {data.row.original.isLocked
                                .map(locked => {
                                    if (locked) {
                                        return <SvgLock className="ml-1" width={20} height={20} />;
                                    }
                                })
                                .extractNullable()}
                        </span>
                    );
                },
            }),
            columnHelper.display({
                id: 'clearCompleted',
                header: () => <span>Clear</span>,
                cell: data =>
                    data.row.original.consideredComplete ? (
                        <TooltipProvider>
                            <FlowDetailDrawerButtonTooltip
                                testId={`${data.row.original.truckName}-clearSingleTruck`}
                                size="tiny"
                                buttonIcon={<SvgBucketMop className="size-5" />}
                                buttonLabel={t(
                                    'pages.positions.detailDrawer.removeCompletedButton.label',
                                )}
                                tooltipContent={t(
                                    'pages.positions.detailDrawer.removeCompletedButton.tooltip',
                                )}
                                loading={false}
                                onClick={e => {
                                    void handleResetClicked(data.row.original.truckName);
                                    e.stopPropagation();
                                }}
                                showLabel={false}
                            />
                        </TooltipProvider>
                    ) : null,
                size: 56,
            }),
        ],
        [toggleRtgCard, delayCodeActive, handleResetClicked],
    );
    return (
        <>
            {instructionsPerWorkQueue.map(
                ({ name, work, loadMode, moveKind, isDualCycle, instructionsLeft }) => {
                    return (
                        <div key={name} className="mt-5 flex flex-col gap-y-4">
                            <WorkQueueTitle
                                title={name}
                                isLoad={moveKind === WorkQueueType.LOAD}
                                isDischarge={moveKind === WorkQueueType.DISCHARGE}
                                loadMode={loadMode}
                                isDualCycling={isDualCycle}
                                instructionsLeft={instructionsLeft}
                            />
                            {work.length > 0 ? (
                                <Table<QuayCraneWorkQueueTableData>
                                    tableLayout="fixed"
                                    data={work}
                                    columns={defaultColumns}
                                    tableClassName="self-start"
                                />
                            ) : (
                                <NoTruckInstructions />
                            )}
                        </div>
                    );
                },
            )}
        </>
    );
};
