import { FlowStatus } from '@/types';

export const calculatePositionBackgroundColor = (
    flowStatus: FlowStatus,
    numberOfChildren: number,
) => {
    const isOverDeployed = flowStatus === FlowStatus.OverDeployed;
    const isStopped = flowStatus === FlowStatus.Stopped;
    const isAtRisk = flowStatus === FlowStatus.Risk;

    const overDeployedPosition = isOverDeployed && numberOfChildren >= 2;
    const stoppedPosition = isStopped && numberOfChildren < 1;
    const atRiskPosition = isAtRisk && numberOfChildren < 1;

    const defaultBackgroundColor = 'bg-gray-200';

    if (overDeployedPosition) {
        return 'bg-feedback-info-weak text-black';
    }

    if (stoppedPosition) {
        return 'bg-feedback-danger text-white';
    }

    if (atRiskPosition) {
        return 'bg-feedback-warning-weak';
    }

    return defaultBackgroundColor;
};
export const calculatePositionBackgroundColorYard = (flowStatus: FlowStatus) => {
    switch (flowStatus) {
        case FlowStatus.Active:
            return 'bg-gray-200';
        case FlowStatus.OverDeployed:
            return 'bg-feedback-info-weak';
        case FlowStatus.Risk:
            return 'bg-feedback-warning-weak';
        case FlowStatus.Stopped:
            return 'bg-feedback-danger-weak';
        default:
            return 'bg-gray-200';
    }
};
