import { ComponentPropsWithRef, PropsWithChildren, ReactNode, forwardRef } from 'react';

import { cn } from '@/utils/cn';

type ComponentProps = ComponentPropsWithRef<'div'>;
type CardProps = ComponentProps & {
    className?: string;
    innerClassName?: string;
    footer?: ReactNode;
};

export const Card = forwardRef<HTMLDivElement, PropsWithChildren<CardProps>>(
    ({ children, className, innerClassName, footer, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn(
                    'relative flex h-full w-full flex-col gap-2 rounded-lg border bg-white shadow-card',
                    className,
                )}
                {...props}
            >
                <div className={cn('flex h-full w-full flex-col p-4', innerClassName)}>
                    {children}
                </div>
                {footer && <div>{footer}</div>}
            </div>
        );
    },
);
