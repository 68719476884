import { SvgVesselFront } from '@maersk-global/apmt-react-icons';
import { FeatureFlagV1 } from '@maersk-global/digital-pull-operating-system-spec';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useShallow } from 'zustand/shallow';

import { GridItem } from '@/components/atoms/GridItem/GridItem';
import { GridOfCards } from '@/components/atoms/GridOfCards/GridOfCards';
import { SecondaryInfo } from '@/components/atoms/SecondaryInfo/SecondaryInfo';
import { Title } from '@/components/atoms/Title/Title';
import { QuayCraneCard } from '@/components/organisms/Cards/QuayCraneCard/QuayCraneCard';
import { QueryStringKeyValues, QueryStringKeys } from '@/constants/enums';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { NoQuayCraneWorkIcon } from '@/icons/components/NoQuayCraneWorkIcon';
import { useAndonsStore } from '@/store';
import { useFilterStore } from '@/store/filterStore';
import { useInstructionAdherence } from '@/store/instructionAdherenceStore';
import {
    LongCranePerVesselVisit,
    MoveInstructionsPerQuayCrane,
    QuayCrane,
    QuayCranes,
    YardWorkInstructions,
} from '@/types';
import { VesselWithQuayCranes } from '@/types/vessels';
import { cn } from '@/utils/cn';
import { createVesselsWithQuayCranesObject } from '@/utils/createVesselsWithQuayCranesObject';
import { isLongCraneSpecification } from '@/utils/filterSpecification/isLongCrane';
import { Specification, all } from '@/utils/filterSpecification/specification';
import { userFilterSpecification } from '@/utils/filterSpecification/userFilter';
import { naturalSort } from '@/utils/naturalSort';

import { QuayCranesFiltersBar } from '../QuayCranesFiltersBar/QuayCranesFiltersBar';

export const FlowOverview = ({
    workQueuesPerQuayCrane,
    longCranePerVesselVisit,
    allQuayCranes,
    yardWorkInstructions,
}: {
    workQueuesPerQuayCrane: MoveInstructionsPerQuayCrane;
    longCranePerVesselVisit: LongCranePerVesselVisit;
    allQuayCranes: QuayCranes;
    yardWorkInstructions: Record<string, YardWorkInstructions>;
}) => {
    const { t } = useTranslation();
    const vesselsWithQuayCranes = createVesselsWithQuayCranesObject(workQueuesPerQuayCrane);
    const noVessels = vesselsWithQuayCranes.length === 0;
    const [searchParams, setSearchParams] = useSearchParams();
    const quayCranesWithActiveWorkQueues = vesselsWithQuayCranes
        .flatMap(vessel => vessel.quayCranes)
        .map(quayCrane => {
            return {
                quayCraneId: quayCrane.quayCraneName,
            };
        });

    const { filterOnLongCranes, setFilterLongCranes, filteredQuayCranes, setFilteredQuayCranes } =
        useFilterStore(
            useShallow(state => ({
                filterOnLongCranes: state.filterOnLongCranes,
                setFilterLongCranes: state.setFilterLongCranes,
                filteredQuayCranes: state.filteredQuayCranes,
                setFilteredQuayCranes: state.setFilteredQuayCranes,
            })),
        );

    const userFilteredQuayCranes = filteredQuayCranes.length > 0;
    const hasActiveFilters = filterOnLongCranes || userFilteredQuayCranes;
    const userFilters = userFilteredQuayCranes
        ? [userFilterSpecification(filteredQuayCranes.map(it => it.quayCraneId))]
        : [];
    const longCraneFilters = filterOnLongCranes
        ? [
              isLongCraneSpecification(
                  Object.values(longCranePerVesselVisit).map(it => it.quayCraneName),
              ),
          ]
        : [];
    const filters: Specification<QuayCrane>[] = [...userFilters, ...longCraneFilters];
    const quayCranesToRender = quayCranesWithActiveWorkQueues
        .filter(all(...filters))
        .map(it => it.quayCraneId);
    const quayCraneFilterOptions = quayCranesWithActiveWorkQueues
        .filter(all(...longCraneFilters))
        .map(quayCrane => {
            return {
                label: quayCrane.quayCraneId,
                value: quayCrane.quayCraneId,
                checked: !filteredQuayCranes
                    .map(it => it.quayCraneId)
                    .includes(quayCrane.quayCraneId),
                disabled: false,
            };
        });

    // Event handlers
    const handleLongCraneSwitch = (checked: boolean) => {
        if (checked) {
            addSearchParams(QueryStringKeys.LONGCRANES, filterOnLongCranes.toString());
        } else {
            deleteSearchParams(QueryStringKeys.LONGCRANES);
        }

        setFilterLongCranes(checked);
    };

    const handleQuayCraneFilterSelection = (quayCraneId: string, checked: boolean) => {
        const newFilteredQuayCranes = checked
            ? filteredQuayCranes.filter(it => it.quayCraneId !== quayCraneId)
            : [...filteredQuayCranes, { quayCraneId }];
        setFilteredQuayCranes(newFilteredQuayCranes);

        // Update url
        const searchParamsString = createSearchParams({
            [QueryStringKeys.QUAYCRANES]: quayCranesWithActiveWorkQueues
                .filter(
                    it => !newFilteredQuayCranes.map(f => f.quayCraneId).includes(it.quayCraneId),
                )
                .filter(all(...longCraneFilters))
                .map(it => it.quayCraneId)
                .sort(naturalSort)
                .toString(),
        });

        updateSearchParams(searchParamsString);
    };

    const handleClearSelection = () => {
        deleteSearchParams(QueryStringKeys.QUAYCRANES);
        setFilteredQuayCranes(quayCranesWithActiveWorkQueues);
    };

    const handleSelectAll = () => {
        setFilteredQuayCranes([]);

        addSearchParams(
            QueryStringKeys.QUAYCRANES,
            filteredQuayCranes
                .map(it => it.quayCraneId)
                .sort(naturalSort)
                .toString(),
        );
    };

    const handleResetFilters = () => {
        updateSearchParams(new URLSearchParams(''));
        setFilteredQuayCranes([]);
        setFilterLongCranes(false);
    };

    const updateSearchParams = (searchParamsString: URLSearchParams) => {
        setSearchParams(searchParamsString, { replace: true });
    };

    const addSearchParams = (searchParamKey: QueryStringKeyValues, searchParamValue: string) => {
        const params = new URLSearchParams(searchParams);
        params.append(searchParamKey, searchParamValue);
        updateSearchParams(params);
    };

    const deleteSearchParams = (searchParamKey: QueryStringKeyValues) => {
        const params = new URLSearchParams(searchParams);
        params.delete(searchParamKey);
        updateSearchParams(params);
    };

    // Set filters from url on load
    useEffect(() => {
        if (searchParams.has(QueryStringKeys.LONGCRANES)) {
            setFilterLongCranes(true);
        }

        if (searchParams.has(QueryStringKeys.QUAYCRANES)) {
            const quayCranes = searchParams.get(QueryStringKeys.QUAYCRANES) ?? '';
            const quayCranesFromUrl = quayCranes.slice(0, quayCranes.length).split(',');
            const quayCranesToFilter = allQuayCranes
                .filter(it => !quayCranesFromUrl.includes(it.quayCraneId))
                .map(it => {
                    return {
                        quayCraneId: it.quayCraneId,
                    };
                });

            setFilteredQuayCranes(quayCranesToFilter);
        }
    }, []);

    return (
        <div
            className={cn('mx-auto w-full p-6', {
                'h-full': noVessels,
            })}
        >
            {noVessels ? (
                <div className="flex h-full flex-col items-center justify-center">
                    <Title className="mds-font--display-3 mb-12">
                        {t('pages.flows.noWorkQueuesOverview')}
                    </Title>
                    <NoQuayCraneWorkIcon />
                </div>
            ) : (
                <>
                    <QuayCranesFiltersBar
                        hasActiveFilters={hasActiveFilters}
                        hasActiveLongCraneFilter={filterOnLongCranes}
                        quayCraneFilterOptions={quayCraneFilterOptions}
                        onLongCranesSwitch={handleLongCraneSwitch}
                        onQuayCranesFilterSelection={handleQuayCraneFilterSelection}
                        onClearSelection={handleClearSelection}
                        onHandleSelectAll={handleSelectAll}
                        onResetFilters={handleResetFilters}
                    />

                    {quayCranesToRender.length !== 0 ? (
                        <GridCards
                            quayCranesToRender={quayCranesToRender}
                            vesselsWithQuayCranes={vesselsWithQuayCranes}
                            longCranePerVesselVisit={longCranePerVesselVisit}
                            yardWorkInstructions={yardWorkInstructions}
                        />
                    ) : (
                        <span className="text-gray-900">{t('labels.filters.no_results')}</span>
                    )}
                </>
            )}
        </div>
    );
};

const GridCards = ({
    quayCranesToRender,
    vesselsWithQuayCranes,
    longCranePerVesselVisit,
    yardWorkInstructions,
}: {
    quayCranesToRender: string[];
    vesselsWithQuayCranes: VesselWithQuayCranes[];
    longCranePerVesselVisit: LongCranePerVesselVisit;
    yardWorkInstructions: Record<string, YardWorkInstructions>;
}) => {
    const andons = useAndonsStore(useShallow(store => store.andons));
    const instructionAdherenceByCrane = useInstructionAdherence(store => store.adherenceByCrane);
    const { data: sendingInstructions } = useFeatureFlag({
        cheType: 'QUAY_CRANE',
        flag: FeatureFlagV1.VMT_INSTRUCTIONS,
    });

    return (
        <GridOfCards as="ol">
            {vesselsWithQuayCranes.map((vessel, index) => {
                const { vesselName, vesselVisitId, quayCranes } = vessel;
                return (
                    <Fragment key={`${vesselVisitId}-${index}`}>
                        {quayCranes
                            .filter(it => quayCranesToRender.includes(it.quayCraneName))
                            .map((quayCrane, quayCraneIndex) => {
                                const {
                                    quayCraneName,
                                    workQueues,
                                    consideredCompletedTruckNames,
                                    flowStatus,
                                } = quayCrane;
                                const isLongCrane =
                                    longCranePerVesselVisit[vesselVisitId]?.quayCraneName ===
                                    quayCraneName;
                                const showVesselName = quayCraneIndex === 0;

                                return (
                                    <GridItem key={quayCraneName} className="min-h-[236px]">
                                        <div className="mb-2 flex min-h-[24px] items-center gap-2">
                                            {showVesselName && (
                                                <>
                                                    <SvgVesselFront className="size-4" />
                                                    <span>{vesselName}</span>
                                                    <SecondaryInfo>{vesselVisitId}</SecondaryInfo>
                                                </>
                                            )}
                                        </div>
                                        <QuayCraneCard
                                            isSendingInstructions={
                                                sendingInstructions?.includes(quayCraneName) ?? true
                                            }
                                            flowStatus={flowStatus}
                                            quayCraneName={quayCraneName}
                                            workQueues={workQueues}
                                            consideredCompletedTruckNames={
                                                consideredCompletedTruckNames ?? []
                                            }
                                            yardWorkInstructions={yardWorkInstructions}
                                            isLongCrane={isLongCrane}
                                            andons={andons}
                                            isAdheringInstructions={
                                                instructionAdherenceByCrane[quayCraneName]
                                                    ?.isAdhering ?? true
                                            }
                                        />
                                    </GridItem>
                                );
                            })}
                    </Fragment>
                );
            })}
        </GridOfCards>
    );
};
