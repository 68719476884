import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
    AdherenceStatus,
    InstructionAdherenceUpdatedEventPayload,
} from '@/types/instructionAdherence';

type AdherenceData = {
    isAdhering: boolean;
};

type InstructionAdherenceStore = {
    adherenceByCrane: { [quayCraneName: string]: AdherenceData };
    setInstructionAdherenceForQuayCrane: (
        adherence: InstructionAdherenceUpdatedEventPayload,
    ) => void;
};

export const useInstructionAdherence = create<InstructionAdherenceStore>()(
    devtools(
        set => {
            return {
                adherenceByCrane: {},
                setInstructionAdherenceForQuayCrane: adherence => {
                    set(state => ({
                        ...state,
                        adherenceByCrane: {
                            ...state.adherenceByCrane,
                            [adherence.quayCraneName]: {
                                isAdhering: adherence.status === AdherenceStatus.ADHERING,
                            },
                        },
                    }));
                },
            };
        },
        { name: 'instructionAdherenceStore' },
    ),
);
