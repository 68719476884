import { UniqueIdentifier } from '@dnd-kit/core';
import { SharedCookieNames } from '@maersk-global/apmt-dpos-common';
import { Divider, Table, Title } from '@maersk-global/apmt-react-components';
import { SvgChevronDown, SvgEye, SvgEyeSlash, SvgTimes } from '@maersk-global/apmt-react-icons';
import {
    FeatureFlagV1,
    FlowPositionEnum,
    YardStackingEquipmentFlagV1,
} from '@maersk-global/digital-pull-operating-system-spec';
import { createColumnHelper } from '@tanstack/react-table';
import { Maybe, NonEmptyList } from 'purify-ts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookie } from 'react-use';
import { useShallow } from 'zustand/shallow';

import { triggerReset } from '@/api';
import { Block } from '@/components/atoms/Block/Block';
import { FlowStatusLabel } from '@/components/atoms/FlowStatusLabel/FlowStatusLabel';
import { Label } from '@/components/atoms/Label/Label';
import { Notification } from '@/components/atoms/Notification/Notification';
import { QuayCraneTitle } from '@/components/molecules/QuayCraneTitle/QuayCraneTitle';
import { Card } from '@/components/organisms/Cards/Card';
import { FlowDetailDrawerQuayCraneStatusInfo } from '@/components/organisms/FlowDetailDrawer/FlowDetailDrawerQuayCraneStatusInfo';
import { FlowPositionName, TruckMapShapes } from '@/constants/enums';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useControlPageStore, useTerminalStore, useTruckPositionsStore } from '@/store';
import { YardWork } from '@/store/yardStore';
import { AssignedPosition, FlowStatus, WorkQueues, YardCheType } from '@/types';
import { cn } from '@/utils/cn';
import { filterCompletedTrucks } from '@/utils/filterCompletedTrucks';
import { yardStatusForYardWork } from '@/utils/getFlowStatus';
import { getTruckShapeIcon } from '@/utils/getTruckShape';
import { toQuayCraneTableData } from '@/utils/quayCraneTableData';
import { toggleFeature } from '@/utils/toggleFeatureFlag';

import { SvgCirlce, SvgSquare, SvgTriangle } from './Icons';
import { QuayCraneInstructionsButtons } from '../../QuayCraneInstructions/QuayCraneInstructionsButtons';
import { QuayCraneInstructionsTable } from '../../QuayCraneInstructionsTable';

export type YardWorkQueues = { id: string; name: string }[];
export interface ControlCardProps {
    id: UniqueIdentifier;
    quayCraneName: string;
    truckShape: TruckMapShapes;
    workQueues: WorkQueues[];
    toggleRtgCard: (cheId: string, checked?: boolean) => void;
    toggleQuayCraneCard: (cheId: string, checked: boolean) => void;
    yardWork: YardWork;
    consideredCompletedTruckNames: string[];
    yardWorkQueues: YardWorkQueues;
    open: boolean;
    isDragging: boolean;
    onOpened: (id: UniqueIdentifier) => void;
    delayCodeActive: boolean;
    className?: string;
    flowStatus: FlowStatus;
    yardCheTypes: { [cheId: string]: YardCheType };
}

export type QuayCraneControlCardProps = {
    kind: 'qc';
} & ControlCardProps;
export type RtgControlCardKind = {
    kind: 'rtg';
};
export type RtgControlCardProps = RtgControlCardKind & RtgControlCardData;
export type RtgJobData = {
    jobNumber: Maybe<number>;
    instruction: Maybe<FlowPositionEnum>;
    from: string;
    to: string;
    container: string;
    direction: Maybe<'receiving' | 'delivering'>;
};
export type QuayCraneWorkQueueTableData = {
    instruction: string;
    hasReachedFlowPosition: Maybe<boolean>;
    isLocked: Maybe<boolean>;
    truckName: string;
    originalInstruction: FlowPositionName | AssignedPosition;
    to: string;
    consideredComplete: boolean;
    kind: 'QC' | 'RTG';
};

export type RtgControlCardData = {
    che: string;
    jobs: RtgJobData[];
    className?: string;
    toggleRtgCard: (cheId: string, checked?: boolean) => void;
    toggleQuayCraneCard: (cheId: string, checked: boolean) => void;
    open: boolean;
    onOpened: (id: UniqueIdentifier) => void;
    isDragging: boolean;
};

export const ControlCard = (props: QuayCraneControlCardProps | RtgControlCardProps) => {
    if (props.kind === 'qc') {
        const {
            open,
            onOpened,
            id,
            quayCraneName,
            truckShape,
            workQueues,
            yardWork,
            yardWorkQueues,
            consideredCompletedTruckNames,
            isDragging,
            yardCheTypes,
            className,
            delayCodeActive,
            flowStatus,
        } = props;
        return (
            <QuayCraneControlCard
                flowStatus={flowStatus}
                delayCodeActive={delayCodeActive}
                open={open}
                onOpened={onOpened}
                id={id}
                quayCraneName={quayCraneName}
                truckShape={truckShape}
                yardCheTypes={yardCheTypes}
                consideredCompletedTruckNames={consideredCompletedTruckNames}
                toggleRtgCard={props.toggleRtgCard}
                toggleQuayCraneCard={props.toggleQuayCraneCard}
                workQueues={workQueues}
                yardWork={yardWork}
                yardWorkQueues={yardWorkQueues}
                isDragging={isDragging}
                className={className}
            />
        );
    } else {
        return <RtgControlCard {...props} />;
    }
};
export const RtgControlCard = ({
    className,
    open,
    isDragging,
    che,
    onOpened,
    toggleRtgCard,
    jobs,
}: RtgControlCardData) => {
    const { t } = useTranslation();
    const [filterEnabled, setFilterEnabled] = useState(false);
    const columnHelper = createColumnHelper<RtgJobData>();
    const flowStatus = yardStatusForYardWork(
        jobs.map(j => ({
            flowPosition: j.instruction.caseOf({
                Just: a => a,
                Nothing: () => FlowPositionEnum.PULL,
            }),
        })),
    );
    const defaultColumns = useMemo(
        () => [
            columnHelper.accessor('jobNumber', {
                header: () => <span> {t('pages.yard.jobTitleDrawer.numberHeader')} </span>,
                cell: data => (
                    <span className="font-sansNumeric">
                        {data
                            .getValue()
                            .map(d => String(d))
                            .orDefault('-')}
                    </span>
                ),
                size: 24,
            }),
            columnHelper.display({
                id: 'from',
                header: () => <span>From</span>,
                cell: info => {
                    const icon =
                        info.row.original.instruction.extract() === FlowPositionEnum.UNDER_CRANE ? (
                            <SvgTriangle />
                        ) : info.row.original.instruction.extract() === FlowPositionEnum.STANDBY ? (
                            <SvgSquare />
                        ) : (
                            <SvgCirlce />
                        );
                    return (
                        <div className="flex flex-row items-center whitespace-nowrap">
                            <div className="mr-1">{icon}</div>
                            {info.row.original.from}
                        </div>
                    );
                },
                size: 80,
            }),
            columnHelper.display({
                id: 'to',
                header: () => <span>To</span>,
                cell: info => {
                    return (
                        <div className="flex flex-row items-center whitespace-nowrap">
                            {info.row.original.to}
                        </div>
                    );
                },
                size: 80,
            }),
            columnHelper.accessor('container', {
                header: () => <span>{t('pages.yard.jobTitleDrawer.containerHeader')}</span>,
                cell: data => {
                    const val = data.getValue();
                    return <span className="relative truncate">{val}</span>;
                },
            }),
        ],
        [],
    );
    const nonEmptyJobs = NonEmptyList.fromArray(jobs);
    const { userFilteredEquipmentOnMapPerQuayCrane, setUserFilteredEquipmentOnMapPerQuayCrane } =
        useControlPageStore(
            useShallow(state => ({
                userFilteredEquipmentOnMapPerQuayCrane:
                    state.userFilteredEquipmentOnMapPerQuayCrane,
                setUserFilteredEquipmentOnMapPerQuayCrane:
                    state.setUserFilteredEquipmentOnMapPerQuayCrane,
            })),
        );
    const handleClick = () => {
        const filteredTrucks = !filterEnabled
            ? [...userFilteredEquipmentOnMapPerQuayCrane, { quayCraneId: che }]
            : userFilteredEquipmentOnMapPerQuayCrane.filter(qc => qc.quayCraneId !== che);

        setUserFilteredEquipmentOnMapPerQuayCrane(filteredTrucks);
    };
    const { data } = useFeatureFlag({
        cheType: 'YSE',
        flag: YardStackingEquipmentFlagV1.VMT_INSTRUCTIONS,
    });
    const showVmtWarning = !data?.includes(che);
    useEffect(() => {
        const isEnabled = !!userFilteredEquipmentOnMapPerQuayCrane.find(
            qc => qc.quayCraneId === che,
        );

        setFilterEnabled(isEnabled);
    }, [userFilteredEquipmentOnMapPerQuayCrane]);
    return (
        <Card className={className} innerClassName="pb-0" data-testid="qc-control-card">
            <div className="rounded-none border-none">
                <Block className="mb-4 cursor-grab items-center justify-between">
                    <div className="flex grow items-center gap-2">
                        <button
                            className={cn('group relative cursor-pointer', {
                                'text-blue-800 opacity-20': filterEnabled,
                                'text-blue-800': !filterEnabled,
                            })}
                            onClick={handleClick}
                        >
                            <SvgEyeSlash
                                className={cn(
                                    'absolute left-1/2 top-1/2 z-20 hidden -translate-x-1/2 -translate-y-1/2 text-blue-50',
                                    {
                                        'group-hover:block': !filterEnabled,
                                    },
                                )}
                            />
                            <SvgEye
                                className={cn(
                                    'absolute left-1/2 top-1/2 z-20 hidden -translate-x-1/2 -translate-y-1/2 text-blue-50',
                                    {
                                        'group-hover:block': filterEnabled,
                                    },
                                )}
                            />
                            <div className="size-8">
                                {getTruckShapeIcon(TruckMapShapes.CIRCLE)({ stroke: true })}
                            </div>
                        </button>
                        <span
                            className={cn('flex grow cursor-move items-center gap-2', {
                                'cursor-grabbing': isDragging,
                            })}
                        >
                            <QuayCraneTitle quayCraneName={che} />
                            <FlowStatusLabel flowStatus={flowStatus} shape="pill" weight="light" />
                        </span>
                    </div>
                    <Chevron
                        onOpen={() => {
                            onOpened(che);
                        }}
                        open={open}
                    />
                    <button
                        onClick={() => toggleRtgCard(che, false)}
                        className="cursor-pointer hover:bg-white"
                        data-testid="close-rtg-card"
                    >
                        <SvgTimes className={cn('shrink-0 text-xl ', {})} />
                    </button>
                </Block>
                {open && (
                    <div className="pb-5">
                        {showVmtWarning && (
                            <Notification
                                fit="medium"
                                className="mb-2 py-2"
                                appearance="cancel"
                                body={
                                    <div className="flex flex-row items-center ">
                                        <span>
                                            DPOS is <span className="underline">not</span> sending
                                            instructions to the VMT
                                        </span>
                                    </div>
                                }
                            />
                        )}

                        <div className="flex flex-col">
                            <Divider className="-mx-4" />
                            {nonEmptyJobs
                                .map(jobs => (
                                    <Table<RtgJobData>
                                        tableLayout="fixed"
                                        data={jobs}
                                        columns={defaultColumns}
                                        tableClassName="self-start"
                                    />
                                ))
                                .orDefault(
                                    <p className="text-gray-600">
                                        {t('pages.positions.detailDrawer.noJobs')}
                                    </p>,
                                )}
                        </div>
                    </div>
                )}
            </div>
        </Card>
    );
};

export const QuayCraneControlCard = ({
    open,
    onOpened,
    id,
    quayCraneName,
    truckShape,
    workQueues,
    yardWork,
    isDragging,
    className,
    toggleRtgCard,
    consideredCompletedTruckNames,
    toggleQuayCraneCard,
    delayCodeActive,
    flowStatus,
    yardCheTypes,
}: ControlCardProps) => {
    const { t } = useTranslation();
    const { terminalId } = useTerminalStore(
        useShallow(state => ({
            terminalId: state.terminalId,
        })),
    );
    const workQueuesWithoutCompletedTrucks = filterCompletedTrucks(
        workQueues,
        consideredCompletedTruckNames,
    );
    const hasActiveWorkQueues = workQueues && workQueues.length > 0;
    const quayCraneStatusInfoPerQuayCrane = useTruckPositionsStore(
        useShallow(store => store.quayCraneStatusInfoPerQuayCrane),
    );
    const quayCraneStatusInfo = quayCraneStatusInfoPerQuayCrane[quayCraneName];
    const [filterEnabled, setFilterEnabled] = useState(false);
    const { userFilteredEquipmentOnMapPerQuayCrane, setUserFilteredEquipmentOnMapPerQuayCrane } =
        useControlPageStore(
            useShallow(state => ({
                userFilteredEquipmentOnMapPerQuayCrane:
                    state.userFilteredEquipmentOnMapPerQuayCrane,
                setUserFilteredEquipmentOnMapPerQuayCrane:
                    state.setUserFilteredEquipmentOnMapPerQuayCrane,
            })),
        );

    const handleClick = () => {
        const filteredTrucks = !filterEnabled
            ? [...userFilteredEquipmentOnMapPerQuayCrane, { quayCraneId: quayCraneName }]
            : userFilteredEquipmentOnMapPerQuayCrane.filter(qc => qc.quayCraneId !== quayCraneName);

        setUserFilteredEquipmentOnMapPerQuayCrane(filteredTrucks);
    };

    useEffect(() => {
        const isEnabled = !!userFilteredEquipmentOnMapPerQuayCrane.find(
            qc => qc.quayCraneId === quayCraneName,
        );

        setFilterEnabled(isEnabled);
    }, [userFilteredEquipmentOnMapPerQuayCrane]);

    const [yardEnabled] = useCookie(SharedCookieNames.YardPage);
    const vesselVisitId = workQueuesWithoutCompletedTrucks?.[0]?.vesselVisitId;

    const flagName = FeatureFlagV1.NO_AD_TRIGGERS;

    const { data } = useFeatureFlag({ cheType: 'QUAY_CRANE', flag: flagName });
    const tosOnlyCraneTriggersEnabled = data?.includes(quayCraneName) ?? false;
    const toggleTosOnlyTriggers = async (checked: boolean) =>
        toggleFeature(
            terminalId,
            quayCraneName,
            { cheType: 'QUAY_CRANE', flag: flagName },
            checked,
        );
    const { data: sendingInstructions } = useFeatureFlag({
        cheType: 'QUAY_CRANE',
        flag: FeatureFlagV1.VMT_INSTRUCTIONS,
    });
    const showVmtWarning = !sendingInstructions?.includes(quayCraneName);
    const handleResetClicked = useCallback(
        async (truckName: string) => {
            await triggerReset(terminalId, quayCraneName, vesselVisitId!, [truckName]);
        },
        [triggerReset, terminalId, vesselVisitId, quayCraneName],
    );

    const instructionsPerWorkQueue = toQuayCraneTableData(
        workQueues,
        consideredCompletedTruckNames,
        quayCraneName,
        !!yardEnabled,
        yardWork,
        yardCheTypes,
        t,
        'small',
    );

    return (
        <Card className={className} innerClassName="pb-0" data-testid="qc-control-card">
            <div className="h-full rounded-none border-none shadow-none">
                <div className="rounded-none border-none">
                    <Block className="mb-4 cursor-grab items-center justify-between">
                        <div className="flex grow items-center gap-2">
                            <button
                                className={cn('group relative cursor-pointer', {
                                    'text-blue-800 opacity-20': filterEnabled,
                                    'text-blue-800': !filterEnabled,
                                })}
                                onClick={handleClick}
                            >
                                <SvgEyeSlash
                                    className={cn(
                                        'absolute left-1/2 top-1/2 z-20 hidden -translate-x-1/2 -translate-y-1/2 text-blue-50',
                                        {
                                            'group-hover:block': !filterEnabled,
                                        },
                                    )}
                                />
                                <SvgEye
                                    className={cn(
                                        'absolute left-1/2 top-1/2 z-20 hidden -translate-x-1/2 -translate-y-1/2 text-blue-50',
                                        {
                                            'group-hover:block': filterEnabled,
                                        },
                                    )}
                                />
                                <div className="size-8">
                                    {getTruckShapeIcon(truckShape)({ stroke: true })}
                                </div>
                            </button>

                            <span
                                className={cn('flex grow cursor-move items-center gap-2', {
                                    'cursor-grabbing': isDragging,
                                })}
                            >
                                <QuayCraneTitle quayCraneName={quayCraneName} />
                                {!hasActiveWorkQueues && (
                                    <Label variant="default" shape="pill">
                                        {t('labels.inactive')}
                                    </Label>
                                )}
                                {hasActiveWorkQueues && (
                                    <FlowStatusLabel
                                        flowStatus={flowStatus}
                                        shape="pill"
                                        weight="light"
                                    />
                                )}
                            </span>
                        </div>
                        <Chevron
                            onOpen={() => {
                                onOpened(id);
                            }}
                            open={open}
                        />
                        <button
                            onClick={() => toggleQuayCraneCard(quayCraneName, false)}
                            className="cursor-pointer hover:bg-white"
                            data-testid="close-qc-card"
                        >
                            <SvgTimes className={cn('shrink-0 text-xl ', {})} />
                        </button>
                    </Block>
                    {open && (
                        <div className="pb-5">
                            {showVmtWarning && (
                                <Notification
                                    fit="medium"
                                    className="mb-2 py-2"
                                    appearance="cancel"
                                    body={
                                        <div className="flex flex-row items-center ">
                                            <span>
                                                DPOS is <span className="underline">not</span>{' '}
                                                sending instructions to the VMT
                                            </span>
                                        </div>
                                    }
                                />
                            )}
                            {hasActiveWorkQueues ? (
                                <div>
                                    <Divider className="-mx-4" />
                                    <Block className="my-4">
                                        <FlowDetailDrawerQuayCraneStatusInfo
                                            quayCraneName={quayCraneName}
                                            quayCraneStatusInfo={quayCraneStatusInfo}
                                            tosOnlyCraneTriggersEnabled={
                                                tosOnlyCraneTriggersEnabled
                                            }
                                            toggleTosOnlyTriggers={toggleTosOnlyTriggers}
                                        />
                                    </Block>
                                    <Divider className="-mx-4" />
                                    <Block className={cn('items-center gap-4', {})}>
                                        <Title className="mds-font--display-1 flex items-center gap-1">
                                            <span className="mds-font--default--bold">
                                                {t('labels.instructions')}
                                            </span>
                                        </Title>
                                        <QuayCraneInstructionsButtons
                                            quayCraneName={quayCraneName}
                                            terminalId={terminalId}
                                            vesselVisitId={vesselVisitId || ''}
                                            showButtonLabels={false}
                                        />
                                    </Block>
                                    <QuayCraneInstructionsTable
                                        instructionsPerWorkQueue={instructionsPerWorkQueue}
                                        delayCodeActive={delayCodeActive}
                                        handleResetClicked={handleResetClicked}
                                        toggleRtgCard={toggleRtgCard}
                                    />
                                </div>
                            ) : (
                                <span className="text-gray-600">
                                    {t('pages.control.noWorkQueueForQuayCrane', {
                                        name: quayCraneName,
                                    })}
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Card>
    );
};

const Chevron = ({ onOpen, open }: { onOpen: () => void; open: boolean }) => {
    return (
        <button
            data-testid="expand-control-card"
            onClick={onOpen}
            className="cursor-pointer hover:bg-white"
        >
            <SvgChevronDown
                className={cn('shrink-0 text-xl transition-transform duration-100', {
                    'rotate-180': open,
                })}
                aria-hidden
            />
        </button>
    );
};
