import { Maybe } from 'purify-ts';

import { FlowStatus } from '@/types';
import { calculatePositionBackgroundColorYard } from '@/utils/calculateFlowPositionBackground';
import { cn } from '@/utils/cn';
export type PullAmount = 'Fits' | 'Stretch' | 'TooMany';
export const FlowPositionTruckBubbleContainer = ({
    hasAndon,
    flowStatus,
    equipment,
    under,
    pull,
    pullAmount,
    standby,
    variant,
    className,
}: {
    hasAndon: boolean;
    flowStatus: FlowStatus;
    equipment: Maybe<string>;
    pull: Maybe<React.ReactNode>;
    pullAmount: PullAmount;
    standby: Maybe<React.ReactNode>;
    under: Maybe<React.ReactNode>;
    variant: 'small' | 'large';
    className?: string;
}) => {
    const pullContent = pull.map(all => <>{all}</>).orDefault(<>-</>);
    const bg = calculatePositionBackgroundColorYard(flowStatus);
    return (
        <div
            className={cn(className, 'flex items-center justify-center', {
                'mds-font--x-small h-5': variant === 'small',
                'mds-font--default h-6': variant === 'large',
            })}
        >
            <span
                className={cn(' text-right font-mono', {
                    'mds-font--x-small--bold text-feedback-danger': hasAndon,
                    'pr-1 w-12': variant === 'small',
                    'pr-4 w-[100px]': variant === 'large',
                })}
            >
                {equipment.orDefault('')}
            </span>

            <div
                className={cn(
                    'bg-gray-200',
                    'leading-5 flex h-full justify-center items-center rounded ',
                    { 'mr-1 w-12': variant === 'small', 'mr-4 w-[100px]': variant === 'large' },
                )}
            >
                {under.orDefault('-')}
            </div>
            <div
                className={cn(
                    standby.map(() => 'bg-gray-200').orDefault(bg),
                    'w-16 leading-5 flex h-full justify-center items-center rounded ',
                    { 'mr-1 w-12': variant === 'small', 'mr-4 w-[100px]': variant === 'large' },
                )}
            >
                {standby.orDefault('-')}
            </div>
            <div
                className={cn(' flex h-full ', {
                    ' w-12 leading-5': variant === 'small',
                    'w-[100px] leading-6': variant === 'large',
                })}
            >
                {pullAmount === 'Fits' ? (
                    <FitsBlock content={pullContent} flowStatus={flowStatus} />
                ) : (
                    <StretchBlock content={pullContent} flowStatus={flowStatus} />
                )}
            </div>
        </div>
    );
};

const FitsBlock = ({
    flowStatus,
    content,
}: {
    content: React.ReactNode;
    flowStatus: FlowStatus;
}) => {
    const bg = calculatePositionBackgroundColorYard(flowStatus);
    return (
        <div
            className={cn(
                bg,
                'px-2 w-16 leading-5 flex h-full mr-1 justify-center items-center rounded ',
            )}
        >
            {content}
        </div>
    );
};
const StretchBlock = ({
    content,
    flowStatus,
}: {
    content: React.ReactNode;
    flowStatus: FlowStatus;
}) => {
    const bg = calculatePositionBackgroundColorYard(flowStatus);
    return (
        <div
            className={cn(
                bg,
                'px-2 min-w-12 overflow-visible flex flex-nowrap rounded items-center ',
            )}
        >
            {content}
        </div>
    );
};
