import { cn } from '@maersk-global/apmt-react-components/src/common/cn';
import { SvgExclamationTriangle } from '@maersk-global/apmt-react-icons';
import { useTranslation } from 'react-i18next';

export const AndonsLabel = ({ andons, className }: { andons: number; className?: string }) => {
    const { t } = useTranslation();

    return (
        <div
            className={cn(
                'flex items-center gap-2 rounded border-2 border-feedback-danger bg-feedback-danger-subtle px-2 py-[6px]',
                className,
            )}
        >
            <SvgExclamationTriangle className="text-xl" />
            {t('labels.andonWithCount', { count: andons })}
        </div>
    );
};
