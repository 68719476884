import { useAuth } from '@maersk-global/apmt-flow-keycloak';
import { InputText, Loader, Switch } from '@maersk-global/apmt-react-components';
import {
    FeatureFlagV1,
    QuayCranePullPositionService,
    YardStackingEquipmentFlagV1,
} from '@maersk-global/digital-pull-operating-system-spec';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Title } from '@/components/atoms/Title/Title';
import { useAllQuayCranes } from '@/hooks/useAllQuayCranes';
import { useAllYardStackingEquipment } from '@/hooks/useAllYardStackingEquipment';
import { FlagType, useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useQuayCraneStore } from '@/store';
import { useTerminalStore } from '@/store/terminalStore';
import { naturalSort } from '@/utils/naturalSort';
import { toggleFeature } from '@/utils/toggleFeatureFlag';

export const FeatureFlagsPage = () => {
    const {
        data: allQuayCranes,
        isLoading: allQuayCranesLoading,
        error: allQuayCranesError,
    } = useAllQuayCranes();
    const { data: allYSEs } = useAllYardStackingEquipment();
    const { user } = useAuth();
    if (allQuayCranesLoading) {
        return <Loader />;
    }

    if (!allQuayCranes) {
        return null;
    }

    if (allQuayCranesError) {
        console.error('Error fetching quayCranes', allQuayCranesError);
        return null;
    }
    const yses = allYSEs?.map(({ name }) => name) ?? [];

    return (
        <div className="p-6">
            <div className="flex flex-col">
                <div className="flex flex-row justify-start space-x-4">
                    <QuayCraneConfig
                        quayCranes={allQuayCranes.map(a => a.quayCraneId)}
                        isAdmin={user.isAdmin}
                    />
                </div>
                <div className="mt-6 flex flex-row">
                    <ConfigPerFlag
                        flagName={{
                            cheType: 'YSE',
                            flag: YardStackingEquipmentFlagV1.VMT_INSTRUCTIONS,
                        }}
                        ches={yses}
                        isAllowedToChange={user.isAdmin}
                    />
                </div>
            </div>
        </div>
    );
};
const ConfigPerFlag = ({
    flagName: flagType,
    ches,
    isAllowedToChange,
}: {
    flagName: FlagType;
    ches: string[];
    isAllowedToChange: boolean;
}) => {
    const { t } = useTranslation();
    const terminalId = useTerminalStore(state => state.terminalId);

    const {
        data: featureFlagData,
        isLoading: featureFlagsLoading,
        error: featureFlagsError,
    } = useFeatureFlag(flagType);
    const toggleFeatureFlag = async (checked: boolean, quayCraneName: string) =>
        toggleFeature(terminalId, quayCraneName, flagType, checked);

    if (featureFlagsLoading) {
        return <Loader />;
    }

    if (!featureFlagData) {
        return <>No feature flags found</>;
    }

    if (featureFlagsError) {
        return null;
    }

    return (
        <div>
            <Title>
                {t(`pages.settings.featureFlags.${flagType.flag}.${flagType.cheType}.title`)}
            </Title>
            <p>
                {t(`pages.settings.featureFlags.${flagType.flag}.${flagType.cheType}.description`)}
            </p>

            <ul className="mt-4 flex w-full flex-col">
                {ches
                    .sort((a, b) => naturalSort(a, b))
                    .map(cheId => {
                        return (
                            <li key={cheId} className="flex space-x-8">
                                <div className="flex w-full max-w-[420px] items-center justify-between border border-x-0 border-b-0 border-gray-300 py-3">
                                    <div>{cheId}</div>
                                    <Switch
                                        name={cheId}
                                        label=""
                                        value={cheId}
                                        checked={featureFlagData.includes(cheId) ? true : false}
                                        disabled={!isAllowedToChange}
                                        className="ml-auto"
                                        onCheckedChange={checked =>
                                            toggleFeatureFlag(checked, cheId)
                                        }
                                    />
                                </div>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};

const QuayCraneConfig = ({ quayCranes, isAdmin }: { quayCranes: string[]; isAdmin: boolean }) => {
    const { t } = useTranslation();
    const terminalId = useTerminalStore(state => state.terminalId);
    const flagType: FlagType = { cheType: 'QUAY_CRANE', flag: FeatureFlagV1.VMT_INSTRUCTIONS };
    const {
        data: featureFlagData,
        isLoading: featureFlagsLoading,
        error: featureFlagsError,
    } = useFeatureFlag(flagType);

    const toggleFeatureFlag = async (checked: boolean, quayCraneName: string) =>
        toggleFeature(terminalId, quayCraneName, flagType, checked);
    const workQueuesPerQuayCrane = useQuayCraneStore(store => store.workQueuesPerQuayCrane);
    const pullPositionDescriptions = Object.fromEntries(
        Object.entries(workQueuesPerQuayCrane).map(([quayCraneId, w]) => [
            quayCraneId,
            w.pullPositionDescription,
        ]),
    );
    const [loading, setLoading] = useState<string[]>([]);
    const setPullPositionDescription = async (description: string, quayCraneId: string) => {
        setLoading([...loading, quayCraneId]);
        await QuayCranePullPositionService.updateDescriptions({
            terminalId,
            requestBody: [
                { description: description === '' ? undefined : description, quayCraneId },
            ],
        });
        setLoading(loading.filter(id => id !== quayCraneId));
    };

    if (featureFlagsLoading) {
        return <Loader />;
    }

    if (!featureFlagData) {
        return <>No feature flags found</>;
    }

    if (featureFlagsError) {
        return null;
    }

    return (
        <div>
            <div className="flex flex-row space-x-8">
                <div className="flex max-w-[420px] flex-col">
                    <Title>
                        {t(
                            `pages.settings.featureFlags.${flagType.flag}.${flagType.cheType}.title`,
                        )}
                    </Title>
                    <p>
                        {t(
                            `pages.settings.featureFlags.${flagType.flag}.${flagType.cheType}.description`,
                        )}
                    </p>
                </div>
                <div className="flex flex-col">
                    <Title>Define a pull area for a QC</Title>
                    <p>
                        Specify a description for the pull area. If you leave it blank, we will use
                        the generic description ("Drive to pull position")
                    </p>
                </div>
            </div>
            <ul className="mt-4 flex w-full flex-col">
                {quayCranes
                    .sort((a, b) => naturalSort(a, b))
                    .map(quayCraneId => {
                        return (
                            <li key={quayCraneId} className="flex space-x-8">
                                <div className="flex w-full max-w-[420px] items-center justify-between border border-x-0 border-b-0 border-gray-300 py-3">
                                    <div>{quayCraneId}</div>

                                    <Switch
                                        name={quayCraneId}
                                        label=""
                                        value={quayCraneId}
                                        checked={
                                            featureFlagData.includes(quayCraneId) ? true : false
                                        }
                                        disabled={!isAdmin}
                                        className="ml-auto"
                                        onCheckedChange={checked =>
                                            toggleFeatureFlag(checked, quayCraneId)
                                        }
                                    />
                                </div>
                                <div className="flex items-center justify-between space-x-4 border border-x-0 border-b-0 border-gray-300 py-3">
                                    <div className="whitespace-nowrap">Pull area</div>
                                    <InputText
                                        icon={
                                            loading.includes(quayCraneId) ? (
                                                <Loader showMessage={false} fit="small" />
                                            ) : null
                                        }
                                        defaultValue={pullPositionDescriptions[quayCraneId] ?? ''}
                                        name={`${quayCraneId}PullArea`}
                                        onBlur={event => {
                                            void setPullPositionDescription(
                                                event.target.value,
                                                quayCraneId,
                                            );
                                        }}
                                        fit="small"
                                        disabled={loading.includes(quayCraneId)}
                                        placeholder="Description"
                                    />
                                </div>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};
