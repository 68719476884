import { SvgCheck } from '@maersk-global/apmt-react-icons';
import { FlowPositionEnum } from '@maersk-global/digital-pull-operating-system-spec';

import { TagContainerSmall } from '@/components/atoms/TagContainerSmall/TagContainerSmall';
import { FlowPositionName, FlowVisualisationVariant, VisualAppearance } from '@/constants/enums';
import { FlowStatus, MoveInstruction } from '@/types';
import { cn } from '@/utils/cn';

export type TruckWithAppeareance = Pick<MoveInstruction, 'truckId'> & {
    appearance: VisualAppearance;
};

export type FlowVisualisationPositions = FlowPositionName | 'IN_YARD';

interface FlowPositionProps {
    id: FlowVisualisationPositions;
    trucks: TruckWithAppeareance[];
    label: string;
    row?: boolean; // if true, the label is next to the position
    error?: boolean;
    singleTag?: boolean;
    variant?: FlowVisualisationVariant;
    flowStatus?: FlowStatus;
}

export const FlowPosition = ({
    trucks,
    label,
    flowStatus = FlowStatus.NotWorking,
    row = false,
    variant = FlowVisualisationVariant.LARGE,
}: FlowPositionProps) => {
    const isLargeVariant = variant === FlowVisualisationVariant.LARGE;
    const isSmallVariant = variant === FlowVisualisationVariant.SMALL;
    const isLargeRow = row && isLargeVariant;
    const isSmallRow = row && isSmallVariant;

    return (
        <div
            className={cn('flex h-full', {
                'flex-col items-center': !row,
                'justify-center': isSmallRow,
            })}
        >
            <span
                className={cn('flex text-gray-600', {
                    'mds-font--default -mt-4': isLargeVariant,
                    'mds-font--x-small mb-1': isSmallVariant,
                    'absolute -left-full h-full items-center': isLargeRow,
                    hidden: isSmallRow,
                })}
            >
                <span
                    className={cn('block w-full lowercase', {
                        'text-center': !isLargeRow || isSmallVariant,

                        'pr-2 text-right': row,
                    })}
                >
                    {label}
                </span>
            </span>

            {isSmallVariant && (
                <TagContainerSmall flowStatus={flowStatus}>
                    {trucks.map(item => {
                        const { truckId } = item;
                        return (
                            <div
                                key={truckId}
                                className="mds-font--x-small flex flex-row items-center leading-3"
                            >
                                {item.appearance === VisualAppearance.DONE && (
                                    <span className="flex h-3 w-5 items-center ">
                                        <SvgCheck className="text-gray-900" />
                                    </span>
                                )}
                                {item.appearance === VisualAppearance.IN_PROGRESS && (
                                    <span className="flex items-center">
                                        <span className="relative inline-flex h-3 w-5 items-center">
                                            <span className="absolute left-px size-1 animate-dot-1 rounded bg-gray-900"></span>
                                            <span className="absolute left-[7px] size-1 animate-dot-2 rounded bg-gray-900"></span>
                                            <span className="absolute left-[13px] size-1 animate-dot-3 rounded bg-gray-900"></span>
                                        </span>
                                    </span>
                                )}
                                {truckId}
                            </div>
                        );
                    })}
                </TagContainerSmall>
            )}
            {isLargeVariant && (
                <TagContainerSmall
                    flowStatus={flowStatus}
                    variant="large"
                    className={cn({
                        'mt-2 min-h-[24px]': isLargeVariant,
                    })}
                >
                    {trucks.map(item => (
                        <div
                            key={item.truckId}
                            className="mds-font--default flex flex-row px-1.5 leading-5"
                        >
                            {item.appearance === VisualAppearance.DONE && (
                                <span className="flex size-5 items-center ">
                                    <SvgCheck className="text-gray-900" />
                                </span>
                            )}
                            {item.appearance === VisualAppearance.IN_PROGRESS && (
                                <span className="flex items-center">
                                    <span className="relative inline-flex size-5 items-center">
                                        <span className="absolute left-px size-1 animate-dot-1 rounded bg-gray-900"></span>
                                        <span className="absolute left-[7px] size-1 animate-dot-2 rounded bg-gray-900"></span>
                                        <span className="absolute left-[13px] size-1 animate-dot-3 rounded bg-gray-900"></span>
                                    </span>
                                </span>
                            )}

                            {item.truckId}
                        </div>
                    ))}
                </TagContainerSmall>
            )}
        </div>
    );
};

export const getTruckAppearance = (
    instruction: FlowPositionName,
    hasReachedFlowPosition: boolean,
) => {
    if (instruction === FlowPositionEnum.PULL) {
        return VisualAppearance.ACTIVE;
    }

    if (hasReachedFlowPosition) {
        return VisualAppearance.DONE;
    } else {
        return VisualAppearance.IN_PROGRESS;
    }
};
