import { Codec, Either, Left, Right, string, optional, array } from "purify-ts";

import { Role } from "./types";

const roleMapping: Map<string, Role> = new Map([
    ["admin", Role.Admin],
    ["normal", Role.Normal],
    ["read-only", Role.ReadOnly],
]);

export const rolesDecoder = Codec.custom<Role[]>({
    decode: (value) => {
        if (!Array.isArray(value)) {
            return Left("Expected array");
        }
        const roles = value.map((entry) => {
            return Either.encase(() => {
                const role = roleMapping.get(entry);
                if (role) {
                    return role;
                } else {
                    throw Error(`Unknown Role ${entry}`);
                }
            });
        });

        return Right(Either.rights(roles));
    },
    encode: (value) => value,
});

export const accessTokenCodec = Codec.interface({
    email: string,
    given_name: optional(string),
    family_name: optional(string),
    realm_access: optional(
        Codec.interface({
            roles: array(string),
        }),
    ),
});
