import { UniqueIdentifier } from '@dnd-kit/core';
import { Loader } from '@maersk-global/apmt-react-components';
import { UtilityBar } from '@maersk-global/apmt-react-layout';
import { FeatureFlagV1 } from '@maersk-global/digital-pull-operating-system-spec';
import { produce } from 'immer';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/shallow';

import { DraggableContainer } from '@/components/molecules/DraggableContainer/DraggableContainer';
import { FloatingAndons } from '@/components/organisms/FloatingAndons/FloatingAndons';
import { FloatingDelayCodes } from '@/components/organisms/FloatingDelayCodes/FloatingDelayCodes';
import { MapComponent } from '@/components/organisms/Map/Map';
import { QuayCraneFilter } from '@/components/organisms/QuayCraneFilter/QuayCraneFilter';
import { TruckMapShapes } from '@/constants/enums';
import { MAX_NUMBER_OF_SELECTED_QUAY_CRANES } from '@/constants/settings';
import { useAllYardStackingEquipment } from '@/hooks/useAllYardStackingEquipment';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import {
    ColumnKeys,
    SortState,
    useControlPageStore,
    useDelayCodeStore,
    useQuayCraneStore,
    useTerminalStore,
} from '@/store';
import { useCraneActivityStore } from '@/store/craneActivity';
import { getYardWorkQueues, useYardStore } from '@/store/yardStore';
import { QuayCraneWithShape } from '@/types';
import { getTruckShapeKey } from '@/utils/getTruckShape';
import { toggleFeature } from '@/utils/toggleFeatureFlag';

import { EditStandByPositionDialog } from '../EditStandbyPositionDialog/EditStandByPositionDialog';
import { FloatingCraneWarnings } from '../FloatingCraneWarnings/FloatingCraneWarnings';
import { filterDuplicateTruckNames } from '../YardEquipmentTable/YardEquipmentTable';

export const ControlOverview = () => {
    const { t } = useTranslation();
    const { setPageTitle, allQuayCranes } = useTerminalStore(
        useShallow(state => ({
            allQuayCranes: state.allQuayCranes,
            setPageTitle: state.setPageTitle,
        })),
    );

    const {
        userSelectedQuayCranes,
        setUserSelectedQuayCranes,
        visibleQuayCranes,
        setVisibleQuayCranes,
    } = useControlPageStore(
        useShallow(state => ({
            setUserSelectedQuayCranes: state.setUserSelectedQuayCranes,
            visibleQuayCranes: state.visibleQuayCranes,
            userSelectedQuayCranes: state.userSelectedQuayCranes,
            setVisibleQuayCranes: state.setVisibleQuayCranes,
        })),
    );

    const { workQueuesPerQuayCrane } = useQuayCraneStore(
        useShallow(state => ({
            workQueuesPerQuayCrane: state.workQueuesPerQuayCrane,
        })),
    );
    const delayCodes = useDelayCodeStore(store => store.delayCodes);
    const updateColumns = (quayCranes: SortState, dragEnd: boolean) => {
        // Filter out empty columns
        if (dragEnd) {
            const newColumns = produce(quayCranes, draft => {
                const columns = Object.values(draft).filter(c => c.filter(Boolean).length > 0);
                draft.root = columns[0] ?? [];
                draft.container1 = columns[1] ?? [];
                draft.container2 = columns[2] ?? [];
                draft.container3 = columns[3] ?? [];
            });
            setVisibleQuayCranes(newColumns);
        } else {
            setVisibleQuayCranes(quayCranes);
        }
    };

    const handleOptionChecked = (quayCraneId: string, checked: boolean) => {
        const filteredQuayCranes: QuayCraneWithShape[] = checked
            ? [
                  ...userSelectedQuayCranes,
                  {
                      kind: 'qc',
                      quayCraneId,
                      columnKey: 'root',
                      shape: getTruckShapeKey(userSelectedQuayCranes.length),
                  },
              ]
            : userSelectedQuayCranes.filter(
                  qc => qc.kind === 'rtg' || qc.quayCraneId !== quayCraneId,
              );
        setUserSelectedQuayCranes(filteredQuayCranes);
    };

    const handleRtgOptionChecked = useCallback(
        (cheId: string, checked?: boolean) => {
            const valueToSet =
                checked === undefined
                    ? !userSelectedQuayCranes.some(s => s.kind === 'rtg' && s.cheId === cheId)
                    : checked;
            const filteredQuayCranes: QuayCraneWithShape[] = valueToSet
                ? [
                      ...userSelectedQuayCranes,
                      {
                          kind: 'rtg',
                          cheId,
                          columnKey: 'root',
                          shape: TruckMapShapes.CIRCLE,
                      },
                  ]
                : userSelectedQuayCranes.filter(qc => qc.kind === 'qc' || qc.cheId !== cheId);

            setUserSelectedQuayCranes(filteredQuayCranes);
        },
        [userSelectedQuayCranes, setUserSelectedQuayCranes],
    );

    useEffect(() => {
        const visibleQuayCraneIds = Object.values(visibleQuayCranes).flat(1);
        const newlyAddedQuayCranes = userSelectedQuayCranes.filter(thing => {
            if (thing.kind === 'qc') {
                return visibleQuayCraneIds.findIndex(({ id }) => id === thing.quayCraneId) === -1;
            }
            if (thing.kind === 'rtg') {
                return visibleQuayCraneIds.findIndex(({ id }) => id === thing.cheId) === -1;
            }
        });

        const userSelectedQuayCraneIds = userSelectedQuayCranes.map(thing => {
            return thing.kind === 'qc'
                ? { kind: thing.kind, quayCraneId: thing.quayCraneId as UniqueIdentifier }
                : { kind: thing.kind, cheId: thing.cheId };
        });

        const addedNewAndRemovedDeselectedQuayCranes = produce(
            visibleQuayCranes,
            (draft: SortState) => {
                newlyAddedQuayCranes.forEach(thing => {
                    const id = thing.kind === 'qc' ? thing.quayCraneId : thing.cheId;
                    draft[thing.columnKey].push({ kind: thing.kind, id });
                });
                Object.entries(draft).forEach(([key, ids]) => {
                    draft[key as unknown as ColumnKeys] = ids.filter(({ id }) => {
                        return (
                            userSelectedQuayCraneIds.findIndex(
                                thing =>
                                    (thing.kind === 'rtg' && id === thing.cheId) ||
                                    (thing.kind === 'qc' && id === thing.quayCraneId),
                            ) > -1
                        );
                    });
                });
            },
        );
        setVisibleQuayCranes(addedNewAndRemovedDeselectedQuayCranes);
    }, [userSelectedQuayCranes]);

    const warnings = useCraneActivityStore(useShallow(store => store.warnings));

    const flagName = FeatureFlagV1.NO_AD_TRIGGERS;

    const { data: cranesWithTosOnlyTriggers = [] } = useFeatureFlag({
        cheType: 'QUAY_CRANE',
        flag: flagName,
    });

    const terminalId = useTerminalStore(useShallow(store => store.terminalId));
    const toggleTosOnlyTriggers = async (checked: boolean, quayCraneName: string) =>
        toggleFeature(
            terminalId,
            quayCraneName,
            { cheType: 'QUAY_CRANE', flag: flagName },
            checked,
        );
    useEffect(() => {
        setPageTitle(t('pages.control.pageTitle'));
    }, [setPageTitle]);
    const { data: allYSEs } = useAllYardStackingEquipment();
    const yardInstructions = useYardStore(useShallow(store => store.yardWorkInstructions));
    const filteredYardInstructions = Object.fromEntries(
        Object.entries(yardInstructions).map(([k, v]) => [
            k,
            { ...v, jobs: v.jobs.filter(filterDuplicateTruckNames) },
        ]),
    );
    const [selectedQuayCraneIds, setSelectedQuayCraneIds] = useState<string[]>([]);
    if (allQuayCranes === undefined) {
        return <Loader />;
    }
    const quayCraneFilterOptions = allQuayCranes.map(({ quayCraneId }) => {
        return {
            label: quayCraneId,
            value: quayCraneId,
            checked: userSelectedQuayCranes.some(
                thing => thing.kind === 'qc' && thing.quayCraneId === quayCraneId,
            ),
            disabled: false,
        };
    });
    const rtgFilterOptions = allYSEs?.map(({ name }) => {
        return {
            label: name,
            value: name,
            checked: userSelectedQuayCranes.some(
                thing => thing.kind === 'rtg' && thing.cheId === name,
            ),
            disabled: false,
        };
    });
    const yardWorkQueues = getYardWorkQueues(yardInstructions);
    const quayCraneIds = userSelectedQuayCranes
        .filter((thing): thing is QuayCraneWithShape & { kind: 'qc' } => thing.kind === 'qc')
        .map(t => ({ quayCraneId: t.quayCraneId }));
    const activeWarnings = Object.entries(warnings)
        .filter(([, warning]) => warning.active)
        .map(([quayCraneName]) => quayCraneName)
        .filter(quayCraneName =>
            quayCraneIds.map(({ quayCraneId }) => quayCraneId).includes(quayCraneName),
        );
    return (
        <>
            <UtilityBar className="relative z-40">
                <div
                    data-testid="utility-bar"
                    // eslint-disable-next-line tailwindcss/no-custom-classname
                    className="flex size-full items-center justify-between text-sm"
                >
                    <QuayCraneFilter
                        label={t('pages.control.filter.title')}
                        options={quayCraneFilterOptions}
                        rtgOptions={rtgFilterOptions}
                        onOptionChecked={handleOptionChecked}
                        onRtgOptionChecked={handleRtgOptionChecked}
                        showClearSelectionButton={false}
                        selectedMaximum={MAX_NUMBER_OF_SELECTED_QUAY_CRANES}
                        selectedMaximumMessage={
                            t('pages.control.filter.selectUpToMax', {
                                max: MAX_NUMBER_OF_SELECTED_QUAY_CRANES,
                            }) || ''
                        }
                    />
                    <EditStandByPositionDialog
                        onOpenChange={open => {
                            if (open) {
                                const qcs = userSelectedQuayCranes
                                    .map(s => s.kind === 'qc' && s.quayCraneId)
                                    .filter((s): s is string => Boolean(s));
                                setSelectedQuayCraneIds(qcs);
                            }
                        }}
                        allQuayCranes={allQuayCranes}
                        selectedQuayCraneIds={selectedQuayCraneIds}
                        setSelectedQuayCraneIds={setSelectedQuayCraneIds}
                        mapEditStartPlace={{
                            startPlace: 'ControlPage',
                            quayCraneId: '',
                        }}
                        label={'pages.editStandByPositionDialog.largeLabel'}
                    />
                </div>
            </UtilityBar>

            <div className="relative mx-auto h-min-navigation-and-utility-bar w-full">
                <MapComponent className="absolute left-0 top-0 h-full" />
                {userSelectedQuayCranes.length > 0 && (
                    <>
                        <div className="absolute left-0 top-0 z-10 w-full p-4">
                            <FloatingCraneWarnings
                                warnings={activeWarnings}
                                cranesWithTosOnlyTriggers={cranesWithTosOnlyTriggers}
                                toggleTosOnlyTriggers={toggleTosOnlyTriggers}
                            />
                            <FloatingDelayCodes quayCranes={quayCraneIds} />
                            <FloatingAndons quayCranes={quayCraneIds} />
                        </div>

                        <DraggableContainer
                            delayCodes={delayCodes}
                            toggleRtgCard={handleRtgOptionChecked}
                            toggleQuayCraneCard={handleOptionChecked}
                            yardWorkQueues={yardWorkQueues}
                            yardWorkPerQuayCrane={filteredYardInstructions}
                            workQueuesPerQuayCrane={workQueuesPerQuayCrane}
                            sortableItems={visibleQuayCranes}
                            updateColumns={updateColumns}
                        />
                    </>
                )}
            </div>
        </>
    );
};
