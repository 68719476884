import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiTruckSide(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path
                d="M10.1366 2.13176C10.2877 2.04542 10.4588 2 10.6328 2H12.3409C12.5479 2 12.7499 2.06428 12.9189 2.18398L18.4764 6.12055C18.5465 6.15861 18.6116 6.20483 18.6703 6.25791L18.789 6.34199L18.7726 6.3651C18.9147 6.53779 19 6.75893 19 7V9C19 9.55229 18.5523 10 18 10H16V17H18V18H2V17H11V10H6V12H7.5V13H3.5V12H5V10H2C1.44772 10 1 9.55228 1 9V7C1 6.44772 1.44772 6 2 6H3.36722L10.1366 2.13176ZM5.38278 6H11V3H10.6328L5.38278 6ZM12 3V6H16.5761L12.3409 3H12ZM17.9879 7H2V9H18V7.00856L17.9879 7ZM12 10V12.191L15 13.691V10H12ZM15 14.809L12 13.309V17H15V14.809Z"
                fill="#F7F7F7"
            />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiTruckSide);
export default ForwardRef;
