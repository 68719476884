import { Title } from '@maersk-global/apmt-react-components/src/Title';
import { DrawerWrapper } from '@maersk-global/apmt-react-layout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/shallow';

import { Block } from '@/components/atoms/Block/Block';
import { DrawerInner } from '@/components/atoms/DrawerInner/DrawerInner';
import { FlowStatusLabel } from '@/components/atoms/FlowStatusLabel/FlowStatusLabel';
import { QuayCraneTitle } from '@/components/molecules/QuayCraneTitle/QuayCraneTitle';
import { routes } from '@/routes/routes';
import { useAndonsStore, useTerminalStore } from '@/store';
import { YardCheType, YardWorkInstructions } from '@/types';
import { getAndonsForEquipment } from '@/utils/getAndonsForEquipment';
import { yardStatus } from '@/utils/getFlowStatus';

import { FlowDetailDrawerAndons } from '../FlowDetailDrawer/FlowDetailDrawerAndons';
import { JobListTable } from '../JobListTable';

type YardOverviewDrawerProps = {
    equipmentData: YardWorkInstructions | undefined;
};

export const YardOverviewDrawer = ({ equipmentData }: YardOverviewDrawerProps) => {
    const terminalId = useTerminalStore(useShallow(state => state.terminalId));
    const navigate = useNavigate();

    const { t } = useTranslation();
    const handleClose = () => {
        navigate(`${routes.yardOverview(terminalId)}`, { replace: true });
    };
    const andons = useAndonsStore(useShallow(store => store.andons));
    const serving =
        equipmentData?.jobs?.reduce((acc: string[], next) => {
            if (next.type === 'InternalJob' && !acc.includes(next.serving)) {
                return [...acc, next.serving];
            }
            return acc;
        }, []) ?? [];
    const andonsRelatedToThisChe = equipmentData
        ? getAndonsForEquipment(andons, [
              { name: equipmentData.che },
              ...serving.map(s => ({ name: s })),
          ])
        : [];
    return (
        <DrawerWrapper
            title={
                equipmentData && (
                    <Block className="min-h-[28px] items-center gap-3">
                        <QuayCraneTitle quayCraneName={equipmentData.che} />
                        <FlowStatusLabel
                            flowStatus={yardStatus(equipmentData)}
                            weight="light"
                            fit="medium"
                            shape="pill"
                        />
                    </Block>
                )
            }
            open={equipmentData !== undefined}
            onClose={handleClose}
        >
            <DrawerInner>
                <div className="mb-5 flex flex-col gap-4">
                    <Title className="mds-font--display-1 flex items-center gap-1">
                        <span className="mds-font--default--bold">{t('labels.jobs')}</span>
                    </Title>
                    {equipmentData?.jobs && equipmentData.jobs.length > 0 ? (
                        <JobListTable
                            jobList={equipmentData.jobs}
                            cheType={equipmentData.cheType ?? YardCheType.UNKNOWN}
                        />
                    ) : (
                        <p className="text-gray-600">{t('pages.positions.detailDrawer.noJobs')}</p>
                    )}
                </div>
                <div className="mb-5">
                    <FlowDetailDrawerAndons andons={andonsRelatedToThisChe} />
                </div>
            </DrawerInner>
        </DrawerWrapper>
    );
};
